.previewOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  
  .carouselContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80vh;
  }
  
  .carouselImage {
    max-width: 80%;
    max-height: 80vh;
    object-fit: contain;
  }
  
  .navButton {
    background: none;
    border: none;
    color: white;
    font-size: 48px;
    cursor: pointer;
    padding: 0 20px;
  }
  
  .prevButton {
    position: absolute;
    left: 20px;
  }
  
  .nextButton {
    position: absolute;
    right: 20px;
  }
  
  .imageCounter {
    color: white;
    font-size: 16px;
    margin-top: 20px;
  }
