.container {
  width: 400px;
  height: 560px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Arial, sans-serif;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.05);
}

.containerHidden {
  display: none;
}

.header {
  background-color: white;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.logo {
  width: 54px;
  height: 54px;
  margin: 15px 15px 9px 20px;
}

.headerText {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* margin-top: 15px; */
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 22.5px;
  color: rgba(26, 26, 26, 1);
}

.subtitle {
  margin: 0 0 0 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.5px;
  color: rgba(140, 140, 140, 1);
}

.searchIcon {
  font-size: 20px;
  color: #888;
}

.searchIcon img {
  height: 44px;
  width: 44px;
  margin-right: 20px;
}

.messageListWrapper {
  flex-grow: 1;
  overflow: hidden;
}

.messageList {
  height: 100%;
  overflow-y: scroll;
  padding: 15px;
  display: flex;
  flex-direction: column;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.messageList::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
  /* Chrome, Safari, Opera */
}

.userMessage {
  align-self: flex-end;
  background: rgba(83, 41, 255, 0.65);
  color: white;
  padding: 10px;
  border-radius: 15px 15px 0 15px;
  max-width: 70%;
  margin-bottom: 10px;
}

.supportMessage {
  align-self: flex-start;
  background-color: #f0f0f0;
  color: black;
  padding: 10px;
  border-radius: 15px 15px 15px 0;
  max-width: 70%;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

.messageTimeUser {
  font-size: 10px;
  color: #fff;
  font-weight: 500;
  line-height: 14px;
  margin-left: 5px;
  align-self: flex-end;
}

.messageTimeBot {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
  line-height: 14px;
  margin-left: 5px;
}

.inputArea {
  border-top: 1px solid #e0e0e0;
  padding: 14px 20px 20px 20px;
  display: flex;
  align-items: center;
}

.inputArea img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}

.input {
  flex-grow: 1;
  margin-right: 10px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  font-size: 14px;
}

.sendButton {
  background: rgba(83, 41, 255, 1);
  height: 44px;
  width: 44px;
  color: white;
  border: none;
  border-radius: 8px;
  opacity: 20%;
  cursor: pointer;
  font-size: 23px;
}

.sendButtonFullfiled {
  background: rgba(83, 41, 255, 1);
  height: 44px;
  width: 44px;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 23px;
}

.sendButtonFullfiled img {
  height: 10px;
  width: 13px;
  margin: 0;
}

.sendButton img {
  height: 10px;
  width: 13px;
  margin: 0;
}

.selected {
  border: 2px solid #5329ff;
}

.contextMenu {
  position: fixed;
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.5px;
}

.contextMenu button {
  display: block;
  width: 100%;
  padding: 8px 10px;
  border: none;
  border-radius: 8px;
  background: none;
  text-align: left;
  cursor: pointer;
}

.contextMenu button:hover {
  background-color: #f0f0f0;
}

.containerNoHide {
  display: flex;
  flex-direction: column;
  height: 700px;
}

.closeButton {
  background: none;
  border: none;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: rgba(140, 140, 140, 1);
  margin-right: 20px;
  transition: color 0.3s ease;
}

.closeButton:hover {
  color: rgba(26, 26, 26, 1);
}

.imagePreviews {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.imagePreviewItem {
  position: relative;
  width: 60px;
  height: 60px;
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.removeImageButton {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: rgb(190, 186, 211);
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  cursor: pointer;
}
